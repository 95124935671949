import { Adsense } from "@ctrl/react-adsense";
import {
  type LoaderFunctionArgs,
  type MetaFunction,
  json,
} from "@remix-run/cloudflare";
import { useLoaderData } from "@remix-run/react";
import { Wrapper } from "~/components/wrapper";
import { getSearchDropdownContent } from "~/lib/utils";
import { HeroSpecialties } from "../components/hero-specialties";
import { HeroTitle } from "../components/hero-title";
import { SearchForm } from "../components/search-form";
import { defaultMetaTags } from "../lib/meta-description";
import { generateRandomRecord } from "../lib/search-utils";

export const meta: MetaFunction = () => {
  return defaultMetaTags();
};
// biome-ignore lint/correctness/noEmptyPattern: <remix stuff>
export const loader = async ({}: LoaderFunctionArgs) => {
  const specialtiesSeo = generateRandomRecord(12, ["specialties"]);
  const citiesSeo = generateRandomRecord(12, ["cities"]);
  const healthInsuracesSeo = generateRandomRecord(12, ["healthInsurance"]);
  const mixSeo = generateRandomRecord(12, [
    "specialties",
    "cities",
    // "states",
    "healthInsurance",
  ]);

  const { healthInsurancesList, specialtiesList, statesAndCities } =
    await getSearchDropdownContent();

  return json({
    specialtiesSeo,
    citiesSeo,
    healthInsuracesSeo,
    mixSeo,
    healthInsurancesList,
    specialtiesList,
    statesAndCities,
  });
};

export default function Home(): JSX.Element {
  const data = useLoaderData<typeof loader>();

  return (
    <>
      <HeroTitle />
      <SearchForm
        healthInsurances={data.healthInsurancesList}
        specialties={data.specialtiesList}
        stateAndCities={data.statesAndCities}
        query={null}
      />
      <Wrapper className="py-4 md:py-6">
        <Adsense
          className=""
          client="ca-pub-9847031185780341"
          slot="9473274455"
          style={{ display: "block" }}
          responsive="true"
          format="auto"
        />
      </Wrapper>
      <div className="ml-auto mr-auto max-w-4xl pl-5 pr-5">
        <HeroSpecialties
          cities={data.citiesSeo}
          healthInsuraces={data.healthInsuracesSeo}
          mix={data.mixSeo}
          specialties={data.specialtiesSeo}
        />
      </div>
    </>
  );
}
